import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Grid, CircularProgress, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClientFormValues } from "types";

interface ClientFormProps {
  initialData?: ClientFormValues;
  onChange: (data: ClientFormValues) => void;
  isLoading?: boolean;
}

export default function ClientForm({
  initialData,
  onChange,
  isLoading = false,
}: ClientFormProps) {
  const { t } = useTranslation(["client", "common", "alert"]);
  
  const { control, formState: { errors }, handleSubmit, watch } = useForm<ClientFormValues>({
    defaultValues: {
      name: initialData?.name || "",
      email: initialData?.email || "",
      workspaceName: initialData?.workspaceName || "",
      organisationId: initialData?.organisationId || "",
    },
    mode: "onChange",
  });

  // Watch all form fields
  const formValues = watch();
  
  // Update parent whenever form values change
  useEffect(() => {
    // Preserve the ID if it exists
    onChange({
      ...formValues,
      id: initialData?.id
    });
  }, [formValues, initialData, onChange]);

  return (
    <form onSubmit={handleSubmit((data) => onChange(data))}>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        {isLoading ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: t("alert:client_name_required") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t("client:client_name")}
                    variant="outlined"
                    required
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{ 
                  required: t("alert:client_email_required"),
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: t("alert:client_email_invalid")
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t("client:client_email")}
                    variant="outlined"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
            {initialData && (
              <Grid item xs={12}>
                <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {t("client:fabric")}
                  </Typography>
                  <Controller
                    name="workspaceName"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 250,
                        message: t("alert:value_too_long")
                      },
                      pattern: {
                        value: /^[^\\",\.%]+$/,
                        message: t("alert:workspace_name_invalid_chars")
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("client:workspace_name")}
                        variant="outlined"
                        error={!!errors.workspaceName}
                        helperText={errors.workspaceName?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </form>
  );
}
