import { FC, useEffect } from "react";
import {
    Box,
    Stack,
    TextField,
    CircularProgress,
    Autocomplete,
    Chip, Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GroupsFormValues, Group } from "types";
import { Controller, useForm } from "react-hook-form";

interface Props {
    onChange: (values: GroupsFormValues) => void;
    initialData?: GroupsFormValues;
    isLoading: boolean;
}

export const GroupsForm: FC<Props> = ({ onChange, initialData, isLoading }) => {
    const { t } = useTranslation();
    const {
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<GroupsFormValues>({
        defaultValues: initialData,
    });

    const formValues = watch();

    /*
    useEffect(() => {
        onChange(formValues);
    }, [formValues, onChange]);

    useEffect(() => {
        reset({
            selectedGroupIds: initialData?.selectedGroupIds || [],
            groups: initialData?.groups || [],
        });
    }, [initialData, reset]);*/

    return (
        <Box component="form" sx={{ mt: 2 }} onSubmit={(e) => e.preventDefault()}>
            <Stack spacing={2}>
                {/* Multi-Select Group Picker */}
                <Typography variant="subtitle1">
                    {t("group:form_info")}
                </Typography>
                <Controller
                    name="selectedGroupIds"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            options={initialData?.groups || []} // Available groups
                            getOptionLabel={(option) => option.name} // Show group names
                            value={initialData?.groups.filter((group) =>
                                field.value.includes(group.id)
                            )} // Pre-select groups
                            onChange={(_, newGroups) => {
                                const newGroupIds = newGroups.map((group) => group.id);
                                setValue("selectedGroupIds", newGroupIds);
                                onChange({
                                    ...formValues,
                                    selectedGroupIds: newGroupIds,
                                });
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disableCloseOnSelect
                            loading={isLoading}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        {...getTagProps({ index })}
                                        key={option.id}
                                        label={option.name}
                                        onDelete={() => {
                                            const updatedIds = field.value.filter(
                                                (id) => id !== option.id
                                            );
                                            setValue("selectedGroupIds", updatedIds);
                                            onChange({
                                                ...formValues,
                                                selectedGroupIds: updatedIds,
                                            });
                                        }}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("client:select_groups")}
                                    placeholder={t("client:search_groups")}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </Stack>
        </Box>
    );
};

export default GroupsForm;
