import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { DownloadForOffline, Visibility as Preview, PreviewTwoTone, Storage } from "@mui/icons-material";

import {
  Connection,
  ConnectionType,
  FetchConnectionPayload,
  FortnoxCompanyInformation,
  SyncType, FortnoxActivationResponse,
  Client,
  ViewDeployment,
} from "types";
import {
  deleteClientConnection,
  fetchClientConnections,
  fetchFortnoxActivationUrl,
  fetchConnection,
  deployClientViews,
  fetchClientViewDeployments,
} from "api/callbacks";
import { TableColumn, TableDataRow } from "types/table";
import { ConnectionForm } from "components/Forms/ConnectionForm";
import { ActionTable } from "components/Table";
import { withAuthentication } from "auth";
import ConfirmationForm from "components/Forms/ConfirmationForm";
import SyncConnectionsDataForm from "components/Forms/SyncConnectionsForm";
import { handleTableAction } from "utils";
import { Box, DialogContent, Typography } from "@mui/material";
import EmailDialog from "components/EmailDialog";
import { AxiosResponse } from "axios";
import { activateNextConnection } from "api/callbacks/next";
import DeployViewsForm from "components/Forms/DeployViewsForm";


const OrganisationClientConnections = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { clientId } = useParams<{ clientId: string }>();
  const [syncType, setSyncType] = useState<SyncType | undefined>();
  const [year, setYear] = useState<number>(2020);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<Connection[]>([]);
  const [formData, setFormData] = useState<CreateConnectionData>();
  const [createResponse, setCreateResponse] = useState<AxiosResponse<FortnoxActivationResponse>>();
  const [deployViewsFormData, setDeployViewsFormData] = useState<{ connectionType: string }>({ 
    connectionType: "Fortnox" 
  });
  const [isDeployingViews, setIsDeployingViews] = useState(false);
  const [viewDeployments, setViewDeployments] = useState<ViewDeployment[]>([]);

  const loadConnections = useCallback(async () => {
    setIsLoading(true);
    if (!clientId) return;
    try {
      const { data: fetchedConnections } = await fetchClientConnections(clientId);
      setConnections(fetchedConnections);
      
      const { data: deployments } = await fetchClientViewDeployments(clientId);
      setViewDeployments(deployments);
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar(t("alert:error_loading_connections"), {
        variant: "error",
      });
    }
    setIsLoading(false);
  }, [clientId, enqueueSnackbar, t]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const handleChange = (syncType: SyncType, syncFromYear: number) => {
    setSyncType(syncType);
    setYear(syncFromYear);
  };

  const extractFortnoxCompanyInformation = (metadata?: { [key: string]: string }) => {
    const companyInfo: FortnoxCompanyInformation | undefined =
      metadata?.companyInformation
        ? JSON.parse(metadata.companyInformation)
        : undefined;

    return {
      companyName: companyInfo?.CompanyName,
      organisationNumber: companyInfo?.OrganizationNumber,
    };
  };

  const columns = useMemo(
    (): TableColumn[] => [
      { name: "connection", label: t("connection:connection"), width: 2 },
      {
        name: "external_id",
        label: t("connection:external_id"),
        width: 2,
      },
      { name: "source", label: t("connection:source"), width: 2 },
      { name: "last_sync", label: t("connection:last_sync"), width: 1 },
    ],
    [t],
  );

  const onSelect = useCallback((item: Connection) => {
    const itemId = item.id;
    setSelected((prevState) =>
      prevState.some((selectedItem) => selectedItem.id === itemId)
        ? prevState.filter((selectedItem) => selectedItem.id !== itemId)
        : [...prevState, item],
    );
  }, []);

  const data = useMemo(
    (): TableDataRow<Connection>[] =>
      connections.map((connection) => {
        let companyName = "";
        let externalId = "";
        if (connection.type === ConnectionType.Fortnox) {
          const companyInfo = extractFortnoxCompanyInformation(connection.metadata);
          companyName = companyInfo.companyName || "";
          externalId = companyInfo.organisationNumber || "";
        }

        if (connection.type === ConnectionType.Next) {
          companyName = "-";
          externalId = connection.metadata?.databaseNumber || "";
        }

        return {
          item: connection,
          onClick: () => onSelect(connection),
          hover: true,
          sx: { cursor: "pointer" },
          values: [
            { name: "connection", value: companyName },
            { name: "external_id", value: externalId },
            { name: "source", value: ConnectionType[connection.type] },
            {
              name: "last_sync",
              value: connection.triggeredTimestampUtc
                ? format(
                  parseISO(connection.triggeredTimestampUtc),
                  "yyyy-MM-dd HH:mm:ss",
                )
                : "",
            },
          ],
        };
      }),
    [connections, onSelect],
  );

  interface CreateConnectionData {
    connectionType: ConnectionType;
    payload: any;
  }

  const handleCreate = useCallback(
    async (closeDialog?: () => void) => {
      if (!clientId || !formData) return;
      const { connectionType, payload } = formData;

      if (connectionType === ConnectionType.Next) {
        await handleTableAction({
          apiCall: () => activateNextConnection({
            clientId: clientId,
            oneTimeApiKey: payload.password,
            nextDatabaseNumber: payload.username,
          }),
          successMessage: t("alert:next_connection_success"),
          failureMessage: t("alert:next_connection_failed"),
          closeDialog,
          callback: loadConnections,
        });
      } else if (connectionType === ConnectionType.Fortnox) {
        await handleTableAction({
          apiCall: () =>
            fetchFortnoxActivationUrl({
              clientId: clientId,
              scopes: payload,
            }),
          closeDialog,
          callback: setCreateResponse,
        });
      }
      setSelected([]);
    },
    [clientId, formData, t, loadConnections],
  );

  const handleDelete = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1) return;
      const connection = selected[0];
      await handleTableAction({
        apiCall: () => deleteClientConnection(String(clientId), connection.id),
        successMessage: t("alert:create_activation_link_success", {
          type: t("connection:connection"),
          name: connection.id,
        }),
        failureMessage: t("alert:delete_fail", {
          type: t("connection:connection"),
          name: connection.id,
        }),
        closeDialog,
        callback: loadConnections,
      });
      setSelected([]);
    },
    [selected, clientId, t, loadConnections],
  );

  const handleSync = useCallback(
    async (closeDialog?: () => void) => {
      if (!connections || !clientId || !syncType) return;
      const payload: FetchConnectionPayload = {
        ClientId: clientId,
        ConnectionIds:
          selected.length === 0
            ? connections.map((x) => x.id)
            : selected.map((x) => x.id),
        StartYear: year,
        FetchType: syncType,
      };
      const result = await handleTableAction({
        apiCall: () => fetchConnection(payload),
        successMessage: t("alert:connection_fetch_started"),
        failureMessage: t("alert:connection_fetch_failed"),
        closeDialog,
      });

      if (result) setSelected([]);
    },
    [selected, connections, clientId, t, syncType, year],
  );

  const handleDeployViews = useCallback(async (closeDialog?: () => void) => {
    if (!clientId) return;
    
    setIsDeployingViews(true);
    try {
      const response = await deployClientViews(clientId, deployViewsFormData.connectionType);
      const message = response.data || t("alert:deploy_views_success");
      
      enqueueSnackbar(message, {
        variant: "success",
      });
      
      // Refresh the view deployments data
      await loadConnections();
      
      // We don't need to close the dialog - removed that part
    } catch (error) {
      console.error("Error deploying views:", error);
      enqueueSnackbar(t("alert:deploy_views_failed"), {
        variant: "error",
      });
    }
    setIsDeployingViews(false);
  }, [clientId, deployViewsFormData.connectionType, enqueueSnackbar, t, loadConnections]);

  const disabledStates = useMemo(
    () => ({
      view:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
            ? t("alert:multiple_items_selected")
            : undefined,

      delete:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
            ? t("alert:multiple_items_selected")
            : undefined,

      sync:
        connections.length === 0
          ? t("alert:no_connections_sync")
          : undefined,
    }),
    [selected, t, connections],
  );

  const handleReportingApps = useCallback(async (app: string, closeDialog?: () => void): Promise<void> => {
    if (selected.length !== 1) return;
    const connection = selected[0];
    const reportingAppsBaseUrl = process.env.REACT_APP_REPORTING_APPS_URI;
    const callUrl = `${reportingAppsBaseUrl}/${app}/${clientId}/${connection.id}`;
    console.log("Call URL", callUrl);
    if (reportingAppsBaseUrl) {
      window.open(callUrl, '_blank');
      debugger;
    }
    if (closeDialog) closeDialog();
  }, [selected, clientId]);

  const handleBudgets = useCallback(async (closeDialog?: () => void): Promise<void> => {
    handleReportingApps("budget", closeDialog)
  }, [handleReportingApps]);

  const handleLiquidity = useCallback(async (closeDialog?: () => void): Promise<void> => {
    handleReportingApps("liquidity", closeDialog)
  }, [handleReportingApps]);

  const actions = useMemo(() => {
    if (!clientId) return undefined;
    return {
      create: {
        dialogTitle: t("connection:create_new_connection"),
        dialogContent: (
          <ConnectionForm onChange={setFormData} clientId={clientId} />
        ),
        onCreate: handleCreate,
      },
      custom: [
        {
          dialogTitle: t("connection:deploy_views"),
          dialogContent: (
            <DeployViewsForm
              onChange={setDeployViewsFormData}
              isLoading={isDeployingViews}
              viewDeployments={viewDeployments}
            />
          ),
          buttonText: t("connection:deploy_views"),
          buttonIcon: <Storage />,
          buttonVariant: "contained",
          dialogActionText: t("deploy"),
          onAction: handleDeployViews,
        }
      ],
    };
  }, [handleCreate, clientId, t, handleDeployViews, viewDeployments, isDeployingViews]);

  const selectedActions = useMemo(() => {
    if (!clientId) return undefined;
    // TODO: Add translations to Budget Actions
    return {
      custom: [
        {
          dialogTitle: t("liquidity:title"),
          dialogContent: (
            <div>
              <DialogContent>
                <Typography variant="body1">
                  {t("liquidity:open_page_message")}
                </Typography>
                <ul>
                  {selected.map(conn => (
                    <li key={conn.id}>
                      {`ID: ${conn.id}`}
                    </li>
                  ))}
                </ul>
              </DialogContent>
            </div>
          ),
          buttonText: t("liquidity:title"),
          buttonIcon: <PreviewTwoTone />,
          buttonVariant: "outlined",
          disabled: disabledStates.view,
          dialogActionText: t("liquidity:open_page_action"),
          onAction: handleLiquidity
        },
        {
          dialogTitle: t("budget:budgets"),
          dialogContent: (
            <div>
              <DialogContent>
                <Typography variant="body1">
                  {t("budget:open_page_message")}
                </Typography>
                <ul>
                  {selected.map(conn => (
                    <li key={conn.id}>
                      {`ID: ${conn.id}`}
                    </li>
                  ))}
                </ul>
              </DialogContent>
            </div>
          ),
          buttonText: t("budget:budgets"),
          buttonIcon: <PreviewTwoTone />,
          buttonVariant: "outlined",
          disabled: disabledStates.view,
          dialogActionText: t("budget:open_page_action"),
          onAction: handleBudgets
        },
        {
          dialogTitle: t("fortnox:sync_data"),
          dialogContent: (
            <SyncConnectionsDataForm
              onChange={(syncType, syncFromYear) => {
                handleChange(syncType, syncFromYear);
              }}
            />
          ),
          buttonText: selected.length === 0
            ? t("fortnox:sync_all")
            : selected.length === 1
              ? t("sync")
              : t("sync") + ` ${selected.length}`,
          buttonIcon: <DownloadForOffline />,
          buttonVariant: "outlined",
          dialogActionText:
            selected.length === 0
              ? t("fortnox:sync_all")
              : selected.length === 1
                ? t("sync")
                : t("sync") + ` ${selected.length}`,
          disabled: disabledStates.sync,
          onAction: handleSync,
        },
        {
          dialogTitle: t("connection:view_connection"),
          dialogContent: (
            <ConnectionForm
              initialData={selected[0]}
              clientId={clientId}
              onChange={() => { }}
            />
          ),
          buttonText: t("view"),
          buttonIcon: <Preview />,
          buttonVariant: "outlined",
          disabled: disabledStates.view,
        },
      ],
      delete: {
        dialogTitle: t("connection:delete_connection"),
        dialogContent: (
          <ConfirmationForm
            itemName={selected[0]?.id}
            itemType={t("connection:connection")}
            additionalWarning={t("cant_undo")}
          />
        ),
        disabled: disabledStates.delete,
        onDelete: handleDelete,
      },
    };
  }, [
    clientId,
    t,
    selected,
    disabledStates.sync,
    disabledStates.view,
    disabledStates.delete,
    handleSync,
    handleDelete,
    handleBudgets,
    handleLiquidity
  ]);

  const onRefetch = useCallback(() => {
    setSelected([]);
    loadConnections();
  }, [loadConnections]);

  return (
    <Box>
      <ActionTable<Connection>
        {...{
          columns,
          data,
          isLoading,
          selected,
          onSelect,
          actions,
          selectedActions,
          onRefetch,
        }}
      />
      {createResponse && (
        <EmailDialog
          createResponse={createResponse}
          onClose={() => setCreateResponse(undefined)}
        />
      )}
    </Box>
  );
};

export default withAuthentication(OrganisationClientConnections);
