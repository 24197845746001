import { FC, useEffect, useState, useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Connection, UsernamePasswordFormValues } from "types";
import { Controller, useForm } from "react-hook-form";

type Props = {
  onChange: (values: UsernamePasswordFormValues) => void;
  initialData?: Connection;
};

const NextConnection: FC<Props> = ({ initialData, onChange }) => {
  const { t } = useTranslation();
  const [metaData, setMetaData] = useState<{ [key: string]: string } | undefined>();
  const [error, setError] = useState<string | undefined>();

  const {
      control,
      reset,
      watch,
      formState: { errors },
    } = useForm<UsernamePasswordFormValues>({
      defaultValues: {
        username: initialData?.metadata?.databaseNumber || "",
        password: (initialData && "******") || "",
      },
  });

  const formValues = watch();

  useEffect(() => {
    onChange(formValues);
  }, [formValues, onChange]);

  useEffect(() => {
    reset({
        username: initialData?.metadata?.databaseNumber || "",
        password: (initialData && "******") || "",
    });
  }, [initialData, reset]);


  return (
    <>
      {error ? (
        <Typography color="error">{t(error)}</Typography>
      ) : (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            {t("connection:connection_details")}
          </Typography>

          <Controller
          name="username"
          control={control}
          rules={{
            required: t("alert:missing_value"),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("connection:database_number")}
              variant="outlined"
              fullWidth
              error={!!errors.username}
              helperText={errors.username ? errors.username.message : ""}
              disabled={!!initialData}
              type="number"
            />
          )}
          
        />
        {!initialData && (
          <Controller
            name="password"
            control={control}
            rules={{
              required: t("alert:missing_value"),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("connection:one_time_api_key")}
                variant="outlined"
                fullWidth
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
              />
            )}
          />
        )}
        </Stack>
      )}
    </>
  );
};

export default NextConnection;
