import { FC, useEffect, useState, useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Connection, FortnoxCompanyInformation } from "types";
import { fetchFortnoxScopes } from "api/callbacks/fortnox";

type Props = {
  onChange?: (value: string[]) => void;
  initialData?: Connection;
};

const FortnoxConnection: FC<Props> = ({ initialData, onChange }) => {
  const { t } = useTranslation();
  const [scopes, setScopes] = useState<string[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [companyInformation, setCompanyInformation] = useState<
    FortnoxCompanyInformation | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const [isInitialized, setIsInitialized] = useState(false);

  const loadFortnoxScopes = useCallback(async () => {
    if (isInitialized) return; // Only load once
    
    try {
      const { data: fetchedScopes } = await fetchFortnoxScopes();
      setScopes(fetchedScopes);

      if (!initialData) {
        setSelectedScopes(fetchedScopes);
        onChange && onChange(fetchedScopes);
      }
      
      setIsInitialized(true);
    } catch (error) {
      setError(t("alert:fortnox_scope_error"));
      console.error("Error fetching Fortnox scopes:", error);
    }
  }, [t, initialData, onChange, isInitialized]);

  useEffect(() => {
    loadFortnoxScopes();
    
    if (initialData && !isInitialized) {
      setSelectedScopes(initialData.metadata?.scope?.split(" ") || []);
      if (initialData?.metadata?.companyInformation) {
        const companyInfo: FortnoxCompanyInformation = JSON.parse(
          initialData.metadata.companyInformation,
        );
        setCompanyInformation(companyInfo);
      }
      setIsInitialized(true);
    }
  }, [initialData, loadFortnoxScopes, isInitialized]);

  const handleScopeChange = (scope: string) => {
    const newScopes = selectedScopes.includes(scope)
      ? selectedScopes.filter((s) => s !== scope)
      : [...selectedScopes, scope];
    
    setSelectedScopes(newScopes);
    onChange && onChange(newScopes);
  };

  return (
    <>
      {error ? (
        <Typography color="error">{t(error)}</Typography>
      ) : (
        <Stack spacing={2}>
          {companyInformation && (
            <Box>
              <Typography variant="body2">
                <Trans
                  i18nKey="fortnox:company_name_with_value"
                  values={{ companyName: companyInformation.CompanyName }}
                  components={{ strong: <strong /> }}
                />
              </Typography>
              <Typography variant="body2">
                <Trans
                  i18nKey="fortnox:organisation_number_with_value"
                  values={{ orgNr: companyInformation.OrganizationNumber }}
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>
          )}

          <Typography variant="subtitle1">
            {initialData
              ? t("connection:connection_details")
              : t("connection:select_scopes")}
          </Typography>

          <Grid container spacing={2}>
            {scopes.map((scope) => (
              <Grid size={{ xs: 4 }} key={scope}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={Boolean(initialData)}
                      checked={selectedScopes.includes(scope)}
                      onChange={() => handleScopeChange(scope)}
                    />
                  }
                  label={t(`scope:${scope}`)}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default FortnoxConnection;
