import { FC, useState, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Connection, ConnectionType } from "types";
import FortnoxConnection from "components/Forms/FortnoxConnectionForm";
import NextConnection from "components/Forms/NextConnectionForm";
import { useForm } from "react-hook-form";

interface ConnectionFormData {
  connectionType: ConnectionType;
  payload: any;
}

interface Props {
  onChange: (value: ConnectionFormData) => void;
  clientId: string;
  initialData?: Connection;
}

export const ConnectionForm: FC<Props> = (props) => {
  const { onChange, clientId, initialData } = props;
  const { t } = useTranslation();
  const [connectionType, setConnectionType] = useState<ConnectionType>(
    initialData?.type ?? ConnectionType.Fortnox
  );

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);

  const handleConnectionTypeChange = (event: SelectChangeEvent<unknown>) => {
    const newConnectionType = event.target.value as ConnectionType;
    setConnectionType(newConnectionType);
  };

  return (
    <Box>
      <FormControl fullWidth sx={{mt: 2}}>
        <InputLabel sx={{ml: -3.5}}>{initialData ? t("connection:connection_type") : t("connection:source")}</InputLabel>
        <Select
          IconComponent={() =>
            dropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
          }
          disabled={Boolean(initialData)}
          sx={{ color: "black", width: "60%" }}
          value={connectionType}
          onChange={handleConnectionTypeChange}
          variant={"standard"}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        >
          <MenuItem value={ConnectionType.Fortnox}>
            {ConnectionType[ConnectionType.Fortnox]}
          </MenuItem>
          <MenuItem value={ConnectionType.Next}>
            {ConnectionType[ConnectionType.Next]}
          </MenuItem>
        </Select>
      </FormControl>
      

      <Box mt={4}>
        {connectionType === ConnectionType.Fortnox && (
          <FortnoxConnection
            initialData={initialData}
            onChange={(scopes) => {
              // Convert to the format expected by parent
              onChange({ connectionType, payload: scopes });
            }}
          />
        )}
        {connectionType === ConnectionType.Next && (
          <NextConnection
            onChange={(values) => {
              onChange({ connectionType, payload: values });
            }}
            initialData={initialData}
          />
        )}
      </Box>
    </Box>
  );
};

