import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";

import ROUTES from "routes";
import { MainLayout } from "layouts";
import { Login } from "pages";
import { useTokenRefresh } from "./hooks/useTokenRefresh";

const App = () => {
  const { inProgress } = useMsal();
  const refreshToken = useTokenRefresh();

  useEffect(() => {
    // Check token status when the component mounts and on focus
    refreshToken();
    window.addEventListener("focus", refreshToken);
    
    return () => {
      window.removeEventListener("focus", refreshToken);
    };
  }, [refreshToken]);

  // Show loading state while authentication is in progress
  if (inProgress !== InteractionStatus.None) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <MainLayout>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/organisations" replace />}
            />
            {ROUTES.filter((route) => route.path !== "/").map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <Route
              path="*"
              element={<Navigate to="/organisations" replace />}
            />
          </Routes>
        </MainLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
};

export default App;
