import React, { useEffect, useState } from "react";
import { 
  CircularProgress, 
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ViewDeployment } from "types";

interface DeployViewsFormProps {
  onChange: (data: { connectionType: string }) => void;
  isLoading?: boolean;
  viewDeployments?: ViewDeployment[];
}

export default function DeployViewsForm({
  onChange,
  isLoading = false,
  viewDeployments = []
}: DeployViewsFormProps) {
  const { t } = useTranslation(["connection", "common"]);
  // List of available connection types (can be expanded in the future)
  const connectionTypes = ["Fortnox"];
  const [selectedType, setSelectedType] = useState(connectionTypes[0]);
  
  // Get deployment info for the selected connection type
  const deploymentInfo = viewDeployments.find(
    d => d.connectionType.toLowerCase() === selectedType.toLowerCase()
  );
  
  // Send the value to parent when it changes
  useEffect(() => {
    onChange({ connectionType: selectedType });
  }, [onChange, selectedType]);

  // Handle radio button change
  const handleConnectionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  return (
    <Box sx={{ p: 2, minWidth: 300, minHeight: 120 }}>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.2)'
                  }}
                >
                  {t("connection:type")}
                </TableCell>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.2)'
                  }}
                >
                  {t("connection:last_deployment")}
                </TableCell>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.2)'
                  }}
                >
                  {t("connection:deployment_status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Map through all connection types */}
              {connectionTypes.map(type => {
                const typeDeployment = viewDeployments.find(
                  d => d.connectionType.toLowerCase() === type.toLowerCase()
                );
                
                return (
                  <TableRow key={type}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          checked={selectedType === type}
                          onChange={handleConnectionTypeChange}
                          value={type}
                          name="connection-type"
                        />
                        {type}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {typeDeployment && typeDeployment.deployedTimestampUtc
                        ? (() => {
                            try {
                              const date = new Date(typeDeployment.deployedTimestampUtc);
                              // Check if date is valid
                              return !isNaN(date.getTime()) 
                                ? format(date, 'yyyy-MM-dd HH:mm')
                                : "";
                            } catch (e) {
                              return "";
                            }
                          })()
                        : t("connection:no_deployments_yet")}
                    </TableCell>
                    <TableCell>
                      {typeDeployment ? typeDeployment.message : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
} 