import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export const useTokenRefresh = () => {
  const { instance } = useMsal();

  const refreshToken = async () => {
    if (instance.getActiveAccount()) {
      try {
        const currentAccount = instance.getActiveAccount();
        const tokenRequest = {
          scopes: [
            process.env.REACT_APP_API_SCOPE!,
            "openid",
            "profile",
            "offline_access"
          ],
          account: currentAccount!,
        };
        
        await instance.acquireTokenSilent(tokenRequest);
        return true;
      } catch (error: any) {
        console.error("Token refresh failed:", error);
        if (error.name === "InteractionRequiredAuthError") {
          await instance.logoutRedirect();
        }
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const initialTimeout = setTimeout(refreshToken, 2000);
    const tokenRefreshInterval = setInterval(refreshToken, 5 * 60 * 1000);
    
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  return refreshToken;
}; 