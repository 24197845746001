import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { OpenInNew, Groups } from "@mui/icons-material";
import { compareDesc, format, parseISO } from "date-fns";

import { withAuthentication } from "auth";
import {Client, ClientFormValues, Group, GroupsFormValues} from "types";
import {
  fetchClients,
  createClient,
  updateClient,
  deleteClient,
  fetchGroups, setClientGroups
} from "api/callbacks";
import { TableColumn, TableDataRow } from "types/table";
import ClientForm from "components/Forms/ClientForm";
import { handleTableAction } from "utils/helpers";
import { ActionTable } from "components/Table";
import ConfirmationForm from "components/Forms/ConfirmationForm";
import GroupsForm from "components/Forms/GroupsForm";

const OrganisationClients = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [formData, setFormData] = useState<ClientFormValues>();
  const [groupFormData, setGroupFormData] = useState<GroupsFormValues>();
  const [selected, setSelected] = useState<Client[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const loadClients = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      try {
        const { data: fetchedClients } = await fetchClients(id);
        setClients(fetchedClients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const loadGroups = useCallback(async () => {
    setIsLoadingGroups(true);
    try {
      const fetchedGroups = await fetchGroups();
      setGroups(fetchedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
    setIsLoadingGroups(false);
  }, []);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const getLatestSync = useCallback((client: Client): string => {
    const syncs = client.connections
      .filter((x) => x.triggeredTimestampUtc)
      .map((connection) => parseISO(connection.triggeredTimestampUtc));

    return syncs.length === 0
      ? ""
      : format(
          syncs.sort((a, b) => compareDesc(a, b))[0],
          "yyyy-MM-dd HH:mm:ss"
        );
  }, []);

  const columns = useMemo(
    (): TableColumn[] => [
      { name: "client", label: t("client:client"), width: 2 },
      { name: "email", label: t("email"), width: 2 },
      {
        name: "fabric_workspace",
        label: t("client:fabric_workspace"),
        width: 2,
      },
      { name: "groups", label: t("client:groups"), width: 1 },
      { name: "connections", label: t("connection:connections"), width: 1 },
      { name: "last_sync", label: t("connection:last_sync"), width: 1 },
    ],
    [t]
  );

  const data = useMemo(
    (): TableDataRow<Client>[] =>
      clients.map((client) => ({
        item: client,
        onClick: () => navigate(`/organisations/${id}/clients/${client.id}`),
        hover: true,
        sx: { cursor: "pointer" },
        values: [
          { name: "client", value: client.name },
          { name: "email", value: client.email },
          {
            name: "fabric_workspace",
            value: client.resourcesInfo ? (
              <Link
                sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                target="_blank"
                href={`https://app.fabric.microsoft.com/groups/${client.resourcesInfo.workspaceId}/list?experience=data-factory`}
                onClick={(e) => e.stopPropagation()}
              >
                {client.resourcesInfo.workspaceName}
                <OpenInNew fontSize="inherit" />
              </Link>
            ) : (
              ""
            ),
          },
          { name: "groups", value: client.accessGroups.length },
          { name: "connections", value: client.connections.length },
          { name: "last_sync", value: getLatestSync(client) },
        ],
      })),
    [clients, getLatestSync, id, navigate]
  );

  const handleCreate = useCallback(
    async (closeDialog?: () => void) => {
      if (formData && id) {
        await handleTableAction({
          apiCall: () => createClient({ ...formData, organisationId: id }),
          successMessage: t("alert:create_success", {
            type: t("client:client"),
            name: formData.name,
          }),
          failureMessage: t("alert:create_fail", {
            type: t("client:client"),
            name: formData.name,
          }),
          closeDialog,
          callback: loadClients,
        });
      }
    },
    [formData, id, t, loadClients]
  );

  const handleEdit = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1 || !formData) return;
      const clientId = selected[0].id;
      const success = await handleTableAction({
        apiCall: () => updateClient(clientId, formData),
        successMessage: t("alert:update_success", {
          type: t("client:client"),
          name: formData.name,
        }),
        failureMessage: t("alert:update_fail", {
          type: t("client:client"),
          name: formData.name,
        }),
        closeDialog,
        callback: loadClients,
      });
      
      if (success) {
        setSelected([]);
      }
    },
    [selected, formData, t, loadClients]
  );

  const handleDelete = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1) return;
      const client = selected[0];
      await handleTableAction({
        apiCall: () => deleteClient(client.id),
        successMessage: t("alert:delete_success", {
          type: t("client:client"),
          name: client.name,
        }),
        failureMessage: t("alert:delete_fail", {
          type: t("client:client"),
          name: client.name,
        }),
        closeDialog,
        callback: loadClients,
      });
      setSelected([]);
    },
    [selected, t, loadClients]
  );

  const handleSetGroups = useCallback(
      async (closeDialog?: () => void) => {
        console.log("handleSetGroups", groupFormData);
        if (selected.length !== 1 || !groupFormData) return;
        const client = selected[0];
        setIsLoadingGroups(true);
        await handleTableAction({
          apiCall: () => setClientGroups(client.id, groupFormData?.selectedGroupIds || []),
          successMessage: t("alert:update_success", {
            type: t("client:client"),
            name: client.name,
          }),
          failureMessage: t("alert:update_fail", {
            type: t("client:client"),
            name: client.name,
          }),
          closeDialog,
          callback: loadClients,
        });
        setSelected([]);
        setGroupFormData(undefined);
        setIsLoadingGroups(false);
      },
      [selected, t, loadClients, groupFormData]
  );

  const onSelect = useCallback((item: Client) => {
    const itemId = item.id;
    setSelected((prevState) =>
      prevState.some((selectedItem) => selectedItem.id === itemId)
        ? prevState.filter((selectedItem) => selectedItem.id !== itemId)
        : [...prevState, item]
    );
  }, []);

  const disabledStates = useMemo(
    () => ({
      edit:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : undefined,

      delete:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : selected[0].connections.length > 0
          ? t("alert:client_delete_disabled_reason")
          : undefined,
    }),
    [selected, t]
  );

  const actions = useMemo(
    () => ({
      create: {
        dialogTitle: t("client:add_new_client"),
        dialogContent: (
          <ClientForm 
            onChange={setFormData}
            isLoading={isLoading} 
          />
        ),
        onCreate: handleCreate,
      },
    }),
    [t, isLoading, handleCreate]
  );

  const selectedActions = useMemo(() => {
    return {
      custom: [{
        dialogTitle: t("client:groups"),
        dialogContent: (
            <GroupsForm
                initialData={{
                  selectedGroupIds: selected[0]?.accessGroups || [],
                  groups: groups
                }}
                onChange={(x) => {
                  // console.log('GroupsForm onChange', x); // Add this line to debug
                  setGroupFormData(x);
                }}
                isLoading={isLoading || isLoadingGroups}
            />
        ),
        disabled: disabledStates.edit,
        buttonText: t("client:groups"),
        buttonIcon: <Groups />,
        buttonVariant: "outlined",
        dialogActionText: t("save_changes"),
        onAction: handleSetGroups,
      }],
      edit: {
        dialogTitle: t("client:edit_client"),
        dialogContent: (
          <ClientForm
            initialData={(() => {
              if (selected.length !== 1) return undefined;
              const client = clients.find((client) => client.id === selected[0]?.id);
              return client ? {
                id: client.id,
                name: client.name || "",
                email: client.email || "",
                organisationId: client.organisationId || "",
                workspaceName: client.resourcesInfo?.workspaceName || ""
              } : undefined;
            })()}
            onChange={(data: ClientFormValues) => {
              setFormData(data);
            }}
            isLoading={isLoading}
          />
        ),
        disabled: disabledStates.edit,
        onEdit: handleEdit,
      },
      delete: {
        dialogTitle: t("client:delete_client"),
        dialogContent: (
          <ConfirmationForm
            itemName={selected[0]?.id}
            itemType={t("client:client")}
            additionalWarning={t("cant_undo")}
          />
        ),
        disabled: disabledStates.delete,
        onDelete: handleDelete,
      },
    };
  }, [
    selected,
    t,
    clients,
    isLoading,
    disabledStates.edit,
    disabledStates.delete,
    handleEdit,
    handleDelete,
    handleSetGroups,
  ]);

  const onRefetch = useCallback(() => {
    setSelected([]);
    loadClients();
  }, [loadClients]);

  return (
    <ActionTable<Client>
      {...{
        columns,
        data,
        isLoading,
        selected,
        onSelect,
        actions,
        selectedActions,
        onRefetch,
      }}
    />
  );
};

export default withAuthentication(OrganisationClients);
