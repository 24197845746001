import { Client, ClientFormValues, Connection, ViewDeployment } from "types";
import { axiosInstance } from "utils";

export const fetchClients = async (organisationId: string) => {
  return await axiosInstance.get<Client[]>(`/organisations/${organisationId}/clients`);
};

export const createClient = async (client: ClientFormValues) => {
  return await axiosInstance.post<Client>("/clients", client);
};

export const fetchClientConnections = async (clientId: string) => {
  return await axiosInstance.get<Connection[]>(
    `/clients/${clientId}/connections`
  );
};

export const fetchClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.get<Connection>(
    `/clients/${clientId}/connection/${connectionId}`
  );
};

export const updateClient = async (id: string, client: ClientFormValues) => {
  return await axiosInstance.put<Client>(`/clients/${id}`, client);
};

export const setClientGroups = async (id: string, groupIds: string[]) => {
  return await axiosInstance.put(`/clients/${id}/groupaccess`, groupIds);
}

export const deleteClient = async (id: string) => {
  return await axiosInstance.delete(`/clients/${id}`);
};

export const deleteClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.delete(
    `/clients/${clientId}/connection/${connectionId}`
  );
};

export const deployClientViews = async (clientId: string, connectionType: string) => {
  return await axiosInstance.post(`/clients/${clientId}/views/${connectionType}`);
};

export const fetchClientViewDeployments = async (clientId: string) => {
  return await axiosInstance.get<ViewDeployment[]>(`/clients/${clientId}/views`);
};
